<!-- 资金转账 -->
<template>
  <div class="CurrencyExchange">
    <p style="
        text-align: left;
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 10px;
      ">
      {{ $t('Fund.transfer_es_ES') }}
    </p>
    <el-form ref="ruleForm" :model="listQuery" :rules="rules" label-position="right" label-width="200px"
             style="text-align: left">
      <!--      登录(注: 编号)-->
      <el-form-item :label="$t('operation.button.login_es_ES')+(':')">
        {{ this.userCode }}
      </el-form-item>
        <!--   发件人   -->
      <el-form-item :label="$t('ic.lable.message.sender')+(':')" prop="acType">
        <el-select v-model="listQuery.acType" placeholder="" style="width: 100%"
                   @change="acTypeChange(listQuery.acType)">
          <el-option value=""/>
          <!--       钱包     -->
          <el-option :label="$t('wallet.russia')" value="B$" />
          <!--       电子钱包     -->
<!--          <el-option :label="$t('elektronik.wallet.russia')" value="F$" />-->
          <!--       奖金钱包     -->
<!--          <el-option :label="$t('bonus.wallet')" value="FB" />-->
          <!--       积分     -->
          <el-option :label="$t('busi.direct.sale_points')" value="H0" />
        </el-select>
      </el-form-item>
        <!--  可用(注: 余额)    -->
      <el-form-item :label="$t('available')+(':')">
        <div v-if="listQuery.acType">
          {{ yue }}&nbsp;&nbsp;
          <span v-if="listQuery.acType === 'F$'">$</span>
          <span v-else-if="listQuery.acType === 'FB'">$</span>
          <span v-else-if="listQuery.acType === 'H0'">{{ $t('busi.direct.sale_points') }}</span>
        </div>
      </el-form-item>
      <!--   发至   -->
      <el-form-item :label="$t('send.to')+(':')" prop="inUserCode">
        <el-row>
          <el-col :span="14">
            <el-input  @input="value=>listQuery.inUserCode=value.toUpperCase()" v-model.trim="listQuery.inUserCode" @blur="blurMember($event.target.value)"/>
          </el-col>
          <el-col :span="8">
            <span v-show="name" style="color: red"> &nbsp; &nbsp;{{ name }}</span>
          </el-col>
        </el-row>
      </el-form-item>
      <!--   金额   -->
      <el-form-item :label="$t('Account.money')+(':')" prop="outMoney">
        <el-input v-model="listQuery.outMoney" type="number" clearable :placeholder="$t('login.pass.amount')" style="width: 50%"/>
      </el-form-item>
      <!--   注意   -->
      <el-form-item :label="$t('Notice')+(':')">
        <el-input v-model="listQuery.memo" type="textarea" />
      </el-form-item>
      <!--   密码   -->
      <el-form-item :label="$t('transaction.password')+(':')" prop="payPassword">
        <el-input v-model="listQuery.payPassword" type="password" readonly onfocus="this.removeAttribute('readonly')" />
      </el-form-item>
      <p  style="color:red;font-size: 20px;text-align: center">{{$t('Please.transaction.password')}}</p>
      <el-button v-loading="saveLoading" plain style="float: right; margin-bottom: 10px" type="warning" @click="btnImplement('ruleForm')">{{ $t('btn.implement') }}</el-button>
    </el-form>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {getExRateAndY, saveOrUpdateFiAcTrans} from "@/api/rus/api"
import {getPersonInfo} from "@/api/common/common"

export default {
  name: "CurrencyExchange",
  data() {
      const validateMoney = (rule, value, callback) => {
          if (value <= 0) {
              return callback(new Error(this.$t('po.setInfo')))
          } else {
              callback()
          }
      }
    return {
      symbol: null,
      wallet: null,
      electronics: null,
      saveLoading: false,
      name: '',
      yue: '',
      listQuery: {
        acType: "",
        inUserCode: "",
        outMoney: '',
        memo: "",
        password: ""
      },
      rules: {
        acType: [{required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"]}],
        inUserCode: [{required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"]}],
        outMoney: [{ required: true, validator: validateMoney, trigger: ['change','blur'] }],
        payPassword: [{required: true, message: this.$t('po.setInfo'), trigger: ["change", "blur"]}]
      }
    }
  },
  computed: {
    ...mapGetters([
      'userCode',
      'companyCode',
    ]),
  },
  created() {
  },
  methods: {
    acTypeChange(val) {
      getExRateAndY({'acType': val}).then(res => {
        this.yue = res.data.yue
      })
    },
    blurMember(val) {
      this.name = ''
      if(val){
        getPersonInfo({'userCode': val}).then(res => {
          this.name = res.data.userName
        }).catch(() => {
          this.name = this.$t('po.setInfo')
        })
      }
    },
    btnImplement(formName) {
      this.listQuery.inUserCode = this.listQuery.inUserCode.toUpperCase()
      if (!this.name || this.name === this.$t('po.setInfo')) {
        return false
      }
      if (this.listQuery.outMoney < 200 && this.listQuery.acType === 'B$') {
        return this.$message.error(this.$t('Transfer.minimum'))
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(this.$t('fi.sure.handle').toString(), this.$t('common.tips').toString(), {
            confirmButtonText: this.$t('button.confirm').toString(),
            cancelButtonText: this.$t('operation.button.cancel').toString(),
            customClass: 'msgBox',
            type: 'warning'
          }).then(() => {
            this.saveLoading = true
            saveOrUpdateFiAcTrans({...this.listQuery})
                .then(() => {
                  this.$router.push({path: 'capitalturn-record'})
                  this.saveLoading = false
                })
                .catch(() => {
                  this.saveLoading = false
                })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: this.$t('operation.button.cancel')
            })
          })
        }
      })
    },
  },
};
</script>

<style scoped>
  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  ::v-deep input[type='number'] {
    -moz-appearance: textfield !important;
  }
</style>
